module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.14.0_@types+react@18.3.12_react@18.3.1__gat_e2pgeysewa7hm6vkocuolgblhu/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["EDEKA Text","EDEKA Head Black"]},"timeout":10000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.9_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-jest@28.11.0_@typescript-eslin_6goayfkbtziyo2ajc2xruy6qca/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
